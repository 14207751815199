import React, { useState, useEffect } from "react";
import Avatar from "react-avatar";
import "./StudentProfile.scss";
import axios from "axios";
import { connect } from "react-redux";
import AllTestsPerformanceReportTabel from "../allTestsPerformanceReportTabel/AllTestsPerformanceReportTabel";
import images from "../../../utilities/images/images";

const StudentProfile = (props) => {
  let {
    token,
    tokenType,
    studentRollNum,
    setUserId,
    setStudentProfileDetails,
    startLoading,
    finishLoading,
    LoaderPopUp,
    loader,
    setParentTarget,
  } = props;
  const [state, setState] = useState({
    studentAttendance: {},
    studentProfile: {},
    userId: "",
  });

  const { studentProfile, userId } = state;
  useEffect(() => {
    getStudentProfile();
  }, []);

  const getStudentProfile = async () => {
    // Get the student profile data
    let data = {
      roll_no: studentRollNum,
    };

    try {
      const response = await axios.post(
        "https://learning.motion.ac.in/motioneducation/api/Parentdashboard",
        data
      );

      if (response.data.status === 200) {
        await setUserId(response?.data?.data?.user_id);
        await setParentTarget(response?.data?.data?.target);
        setState((prevState) => ({
          ...prevState,
          studentProfile: response.data,
          userId: response.data.data.user_id,
        }));
        setStudentProfileDetails(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="studentProfile">
        <section className="container">
          <div className="Card">
            <div class="card-body d-flex">
              <div className="left d-flex">
                <div className="profile-img">
                  {studentProfile?.data?.image ? (
                    <img src={studentProfile?.data?.image} alt="profile-img" />
                  ) : (
                    <Avatar
                      name={studentProfile?.data?.name}
                      round={true}
                      size="205"
                      bgColor="#b50303"
                    />
                  )}
                </div>
                <div className="profile-content">
                  <h2>{studentProfile?.data?.name}</h2>
                  <ul>
                    <li>
                      <span className="lft-li">Target</span>
                      <span className="rght-li">
                        {studentProfile?.data?.target}
                      </span>
                    </li>
                    <li>
                      <span className="lft-li">Center</span>
                      <span className="rght-li">
                        {studentProfile?.data?.center}
                      </span>
                    </li>
                    <li>
                      <span className="lft-li">Roll.no</span>
                      <span className="rght-li">
                        {studentProfile?.data?.roll_no}
                      </span>
                    </li>
                    <li>
                      <span className="lft-li">Class</span>
                      <span className="rght-li">
                        {studentProfile?.data?.classes}
                      </span>
                    </li>
                    <li>
                      <span className="lft-li">Course</span>
                      <span className="rght-li">
                        {studentProfile?.data?.course}
                      </span>
                    </li>
                    <li>
                      <span className="lft-li">Batch</span>
                      <span className="rght-li">
                        {studentProfile?.data?.batch}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="right d-flex">
                <div className="reportCard totalTests">
                  <div className="top tt">TotalTests</div>
                  <div className="btm">
                    <div className="num">{studentProfile?.total_test}</div>

                    <div className="text">Main + Advance</div>
                  </div>
                </div>

                <div className="reportCard appeared">
                  <div className="top app">Appeared</div>
                  <div className="btm">
                    <div className="num">{studentProfile?.appeared_count}</div>

                    <div className="text">Main + Advance</div>
                  </div>
                </div>

                <div className="reportCard attendance">
                  <div className="top att">Attendance</div>
                  <div className="btm">
                    <div className="classes">
                      <span className="text">Classes</span>{" "}
                      <span className="num">{studentProfile?.classes}</span>
                    </div>
                    <div className="atndce">
                      <span className="text">Attended</span>
                      <span className="num">{studentProfile?.attendance}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {userId && (
        <AllTestsPerformanceReportTabel
          finishLoading={finishLoading}
          startLoading={startLoading}
          loader={loader}
          LoaderPopUp={LoaderPopUp}
          userId={userId}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
    token: state.auth.authToken,
  };
};

export default connect(mapStateToProps, null)(StudentProfile);

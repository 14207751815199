import React, { useState } from "react";
import "./QuestionDetailPanel.scss";
import images from "../../../../../../../utilities/images/images";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import QuestionInfoSection from "./questionInfoSection/QuestionInfoSection";
import QuestionOptions from "./questionOptions/QuestionOptions";
import QuestionSolution from "./questionSolution/QuestionSolution";

const QuestionDetailPanel = ({
  testQuestionsDetails,
  currentQuestion,
  currentSolutionDetails,
  scrollUp2,
  scrollUp,
  handlePreviousQuestionBtn,
  handleNextQuestionBtn,
}) => {
  const history = useHistory();

  const [lang, setLang] = useState(true);

  // const handleGoBack = () => {
  //   history.push({
  //     pathname: "/student-tests",
  //     state: { step: 2 },
  //   });
  // };

  const handleRedirect = () => {
    history.push({
      pathname: "/student-report-pdf",
    });
  };

  let ConvertStringToHTML = function (str) {
    if (!str) {
      return false;
    }
    let parser = new DOMParser();
    let doc = parser.parseFromString(str, "text/html");

    return doc.body.children.length > 0;
  };

  return (
    <div>
      <div className="QuestionDetailPanel">
        <div className="timer_container d-flex justify-content-between">
          <div className="question_counter">
            <span>{currentQuestion + 1}</span>
            {` / `}
            <span>{testQuestionsDetails?.data?.length}</span>
          </div>
          <div className="section-heading">RBTS Full Test</div>
          <div className="downloadReportBtn">
            <button>
              <span className="text" onClick={handleRedirect}>
                Download report
              </span>
              <span>
                <div className="img">
                  <img
                    style={{ marginTop: "2px" }}
                    src={images.user_activity_48_1}
                    alt="img"
                  />
                </div>
              </span>
            </button>
          </div>
        </div>

        <div
          className="section_wrapper"
          style={{ overflowY: "scroll", padding: "20px", height: "78vh" }}
        >
          <QuestionInfoSection
            currentSolutionDetails={currentSolutionDetails}
            currentQuestion={currentQuestion}
            lang={lang}
            setLang={setLang}
            ConvertStringToHTML={ConvertStringToHTML}
          />

          <QuestionOptions
            currentSolutionDetails={currentSolutionDetails}
            currentQuestion={currentQuestion}
            lang={lang}
            testQuestionsDetails={testQuestionsDetails}
          />

          <QuestionSolution
            currentSolutionDetails={currentSolutionDetails}
            lang={lang}
            ConvertStringToHTML={ConvertStringToHTML}
          />
        </div>
      </div>
    </div>
  );
};

export default QuestionDetailPanel;

import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import "./package_details_component.scss";
import images from "../../../../../../utilities/images/images";
import { Accordion } from "react-bootstrap";
import Slider from "react-slick";
import VideoDrmPlayer from "../../../../features/video_feature/video_feature_content_component/VideoDrmPlayer";
import { Render } from "../../../../../homepage/Render";
import CcAvenuePayment from "../../../../../../custom_utilities/ccavence_payment/CcAvenuePayment";
import { url_177 } from "../../../../../../custom_utilities/api_services";
import { useSelector, useDispatch } from "react-redux";
import CouponList from "../modal/Coupons/CouponList";
import TermsAndCondition from "../modal/TermsAndCondition/TermsAndCondition";
import userActivityActions from "../../../../../../redux/user_activity/action";
import FacultyDetail from "../modal/FacultyDetail/FacultyDetail";
import LoaderPopUp from "../../../../../../components/loader/loader";

import HeaderComponent from "../../../../structure_components/desktop_screens/header_component/header_component";
import { toastifyMessage } from "../../../../../../custom_utilities/toastify_config";
import "react-toastify/dist/ReactToastify.css";
import uiActions from "../../../../../../redux/ui/action";

const PackageDetailsComponent = () => {
  const dispatch = useDispatch();

  const paymentDetails = useSelector(
    (state) => state.paymentReducer.ccAvenueDetails
  );

  const startLoading = () => {
    dispatch(uiActions.startLoading());
  };

  const finishLoading = () => {
    dispatch(uiActions.finishLoading());
  };

  const setSubjectData = (data) => {
    dispatch(
      userActivityActions.setSubjectData({
        subjectId: data.sub_id,
        subjectName: data.sub_name,
        subjectIcon: data.icon,
      })
    );
    history.push("/subject_component");
  };

  const userId = useSelector((state) => state.auth.user.user_id);
  const loader = useSelector((state) => state.ui.loader);

  const packageDetails = useSelector(
    (state) => state.PackageSliceReducer.storeCurrentPackageDetails
  );

  const { package_id } = packageDetails;

  const [packageData, setPackageData] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [couponDiscount, setCouponDiscount] = useState(0);
  const [finalAmount, setFinalAmount] = useState();
  const [showCouponList, setShowCouponList] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [showFacultyDetails, setShowFacultyDetails] = useState(false);
  const [FacultyDetails, setFacultyDetails] = useState(null);

  const [active, setActive] = useState("desc");

  const history = useHistory();

  const description = packageData?.PackageInstallmentDetails?.description || "";
  const truncatedDescription =
    description.length > 220
      ? description.substring(0, 220) + "..."
      : description;

  //redeem_motion_coins.eligible_coins
  useEffect(() => {
    const fetchPackageData = async () => {
      try {
        const response = await axios.get(
          `https://learning.motion.ac.in/motioneducation/api/user/package_detail_web?package_id=${package_id}`
        );
        setPackageData(response.data.data);
        setFinalAmount(
          parseFloat(response.data.data.price_details?.course_price) -
            parseFloat(response.data.data?.price_details?.discount)
        );
      } catch (error) {
        console.error(error);
      }
    };

    fetchPackageData();
  }, [package_id]);

  const applyCoupon = async () => {
    if (couponCode.length === 0) {
      alert("Please Enter the Coupon Code");
      return;
    }

    startLoading();
    try {
      const payloadData = {
        user_id: userId,
        package_id: package_id,
        order_id: paymentDetails.Order_Id,
        amount:
          parseFloat(packageData?.price_details.course_price) -
            parseFloat(packageData?.price_details.discount) || 0,
        coupon_code: couponCode,
      };

      const response = await axios.post(
        "https://learning.motion.ac.in/motioneducation/api/user/apply_couponweb",
        payloadData
      );

      if (response.data.status === 200) {
        finishLoading();
        toastifyMessage({
          status: response.data.data.status,
          message: response.data.data.message,
        });

        setFinalAmount(response.data.data.updated_amount);

        setCouponDiscount(finalAmount - response.data.data.updated_amount);
        handleCloseCoupon();
      }

      console.log("response of coupon", response);
    } catch (error) {
      console.error("Error applying coupon", error);
      handleCloseCoupon();
    }
  };

  const handleCheckboxChange = (event) => {
    const checked = event.target.checked;
    setIsChecked(checked);

    if (checked) {
      // Call the API if the checkbox is checked
      applyCoinsApi();
    }
  };

  const applyCoinsApi = async () => {
    try {
      startLoading();
      let payloadData = {
        cart_amount: finalAmount,

        coins_count: packageData?.redeem_motion_coins?.eligible_coins,
        type: "enquiry",
        order_id: paymentDetails.Order_Id,
        checked: true,
      };

      const response = await axios.post(url_177, payloadData);

      if (response.data.status === 200) {
        finishLoading();
        setFinalAmount(response.data.data?.updated_amount);
      }
    } catch (error) {
      finishLoading();
    }
  };

  const settings = {
    infinite: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: false,
    speed: 3000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const openCouponList = () => {
    setShowCouponList(true);
  };

  const handleCloseCoupon = () => {
    setShowCouponList(false);
  };

  const openTerms = () => {
    setShowTerms(true);
  };

  const closeTerms = () => {
    setShowTerms(false);
  };

  const openFacultyDetails = (data) => {
    setFacultyDetails(data);
    setShowFacultyDetails(true);
  };
  const closeFacultyDetails = () => {
    setShowFacultyDetails(false);
  };

  const checkApplyCouponBtnDisabled = () => {
    if (couponCode.length === 0) {
      return "disableapplybtn";
    }

    return "";
  };

  const handleOnChangeCouponCode = (e) => {
    setCouponCode(e.target.value);
  };

  const changeTab = (tab) => {
    setActive(tab);
  };

  const whatsAppMessageSender = () => {
    const phoneNumber = parseInt(packageData?.motion_whatsapp_no);

    const url = `https://api.whatsapp.com/send?phone=${phoneNumber}`;
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const expiryDate = packageData?.PackageInstallmentDetails?.expiry_date;

  // Check if the expiry date is not "0000-00-00"
  const shouldShowExpiryDate = expiryDate && expiryDate !== "0000-00-00";

  const goBack = () => {
    history.goBack();
  };

  return (
    <Fragment>
      <HeaderComponent />
      <div
        style={{
          background: " #F9F9F9",
          display: "flex",
          justifyContent: "center",
          gap: "50px",
          paddingTop: "61px",
        }}
      >
        <div style={{ marginTop: "31px" }}>
          <div className="storeBack" onClick={goBack}>
            <img src={images.storeBck} alt="back" />
            Back
          </div>
          <div class="user-dashboard-store-banner-inner-wrapper">
            <div class="store-banner-content-and-btn-wrapper">
              <div class="text">
                {packageData?.PackageInstallmentDetails?.package_name}
              </div>
              <img
                src={packageData?.PackageInstallmentDetails?.icon}
                alt="banner"
              />

              {packageData?.PackageInstallmentDetails?.thumbnail_tag && (
                <div
                  className="new-label"
                  style={{
                    backgroundColor:
                      packageData?.PackageInstallmentDetails?.tag_background,
                    color:
                      packageData?.PackageInstallmentDetails?.tag_textcolor,
                  }}
                >
                  {packageData?.PackageInstallmentDetails?.thumbnail_tag}
                </div>
              )}
            </div>
            <div className="tabs-container">
              <div className="tabs">
                <div
                  className={`tab ${active === "desc" ? "active" : ""}`}
                  onClick={() => changeTab("desc")}
                >
                  <i className="icon description-icon"></i>
                  Description
                </div>
                <div
                  className={`tab ${active === "subjects" ? "active" : ""}`}
                  onClick={() => changeTab("subjects")}
                >
                  <i className="icon subjects-icon"></i>
                  Subjects
                </div>
              </div>
              <div className="contact-icons">
                <div
                  className="icon-button whatsapp"
                  onClick={whatsAppMessageSender}
                >
                  <img src={images.whatsaap} alt="WhatsApp" />
                </div>

                <div className="icon-button call">
                  <a href={`tel:${packageData?.motion_mobile_no}`}>
                    <img src={images.phoneCall} alt="Call" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          {active === "desc" && (
            <>
              <Render if={!packageData?.description}>
                <div className="description-inner-wrapper">
                  <div className="description-content-and-btn-wrapper">
                    <h3>Description</h3>

                    <div className="description-content-wrapper">
                      <div className="description-content">
                        <div className="description-heading">
                          {isExpanded ? description : truncatedDescription}
                          {description.length > 220 && (
                            <button
                              className="view-more-btn"
                              onClick={() => setIsExpanded(!isExpanded)}
                            >
                              {isExpanded ? "View Less" : "View More"}
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Render>
              <Render if={!!packageData?.orientation_video}>
                <div className="video-inner-wrapper">
                  <div className="video-content-and-btn-wrapper">
                    <h3>Orientation Video</h3>
                  </div>
                  <div style={{ width: "100%", height: "300px" }}>
                    <VideoDrmPlayer id_video={packageData?.orientation_video} />
                  </div>
                </div>
              </Render>
              <Render
                if={
                  packageData?.PackageInstallmentDetails?.course_benefits
                    .length > 0
                }
              >
                <div className="course-benefits-inner-wrapper">
                  <h3>Course Benefits</h3>
                  <div className="course-benefits-list">
                    {packageData?.PackageInstallmentDetails?.course_benefits?.map(
                      (benefit, index) => (
                        <div key={index} className="course-benefit-item">
                          <img
                            src={benefit.icon}
                            alt={benefit.name}
                            className="benefit-icon"
                          />
                          <span className="course-benefit-name">
                            {benefit.name}
                          </span>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </Render>

              <div className="performance-inner-wrapper">
                <div className="performance-benefits-list">
                  <div className="performance-benefit-item">
                    <img
                      src={images?.learningPerformance}
                      alt={"learningPerformance"}
                      className="benefit-icon"
                    />
                    <span className="performance-benefit-name">
                      Improve Your Overall Learning Performance
                    </span>
                  </div>
                </div>
              </div>

              <Render if={packageData?.faculty_list.length > 0}>
                <div className="faculty-details-inner-wrapper">
                  <h3>Faculty details</h3>
                  <Slider {...settings}>
                    {packageData?.faculty_list?.map((faculty) => (
                      <div key={faculty?.user_id} className="faculty-slide">
                        <div
                          style={{
                            backgroundColor: "#F6F8F8",
                            padding: "15px",
                            width: "136px",
                          }}
                        >
                          <div className="faculty-image">
                            <img src={faculty?.thumnail} alt={faculty?.name} />
                          </div>
                          <div className="faculty-name">By {faculty?.name}</div>
                          <div className="view-more">
                            <button onClick={() => openFacultyDetails(faculty)}>
                              View more
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
              </Render>
              <Render if={packageData?.other_details?.length > 0}>
                <div className="other-details-inner-wrapper">
                  <h3>Other details</h3>
                  <ul>
                    {packageData?.other_details?.map((item, index) => (
                      <li key={index}>{item.name}</li>
                    ))}
                  </ul>
                </div>
              </Render>
              <Render if={packageData?.faq?.length > 0}>
                <div className="faq-inner-wrapper">
                  <h3>FAQ's (Frequently asked questions)</h3>
                  <Accordion className="faq-accordion">
                    {packageData?.faq?.map((faq, index) => (
                      <Accordion.Item key={index} eventKey={index.toString()}>
                        <Accordion.Header>{faq.name}</Accordion.Header>
                        <Accordion.Body>{faq.description}</Accordion.Body>
                      </Accordion.Item>
                    ))}
                  </Accordion>
                </div>
              </Render>
            </>
          )}

          {active === "subjects" && (
            <div className="subject-tab-container">
              {packageData?.subject?.map((sub) => {
                return (
                  <div
                    key={sub.sub_id}
                    className="single-sub"
                    style={{ background: sub.color }}
                    onClick={() => setSubjectData(sub)}
                  >
                    <div className="sub-content">
                      <img className="sub-image" src={sub.icon} alt="img" />
                      <div>
                        <div className="subname"> {sub.subject_name}</div>
                        <div className="subtopics">
                          {sub.total_topics} topics
                        </div>
                      </div>
                    </div>

                    <img
                      className="arrw-image"
                      src={images.storeRightArrow}
                      alt="arrow"
                    />
                  </div>
                );
              })}
            </div>
          )}
        </div>

        <div
          className="user-dashboard-coupon-referral-wrapperr"
          style={{ marginTop: "70px" }}
        >
          <div className="header">
            <div className="title">
              <img src={images.coupon} alt="Coupon icon" />
              <span>Coupon/Referral</span>
              <button className="view-coupons" onClick={openCouponList}>
                View Coupons
              </button>
            </div>
          </div>

          <div className="coupon-input">
            <input
              type="text"
              placeholder=" Enter Coupon/Referral Code"
              value={couponCode}
              onChange={(e) => handleOnChangeCouponCode(e)}
            />
            <button
              // disabled={checkApplyCouponBtnDisabled}
              onClick={applyCoupon}
              className={checkApplyCouponBtnDisabled}
            >
              Apply
            </button>
          </div>

          <div className="coins-reedem-wrapper">
            <div className="coins-reedem-inner-wrapper">
              {/* <img src={images.coinImg} alt="coins" /> */}
              <img src={images.coinImg} alt="coin" />
              <div className="coins-reedem-content">
                <div className="reedem-heading">Redeem Motion Coins</div>
                <div className="coins-count">
                  Available :
                  <span>
                    {packageData?.redeem_motion_coins?.eligible_coins}
                  </span>
                </div>
              </div>
            </div>

            <div className="coins-input">
              <input
                type="checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
                disabled={
                  packageData?.redeem_motion_coins?.eligible_coins === 0
                }
              />
            </div>
          </div>

          <div className="price-details">
            <h3>Price details</h3>
            <div className="price-row">
              <span>Course Price</span>
              <span>
                ₹
                {parseFloat(packageData?.price_details?.course_price).toFixed(
                  2
                )}
              </span>
            </div>
            <div className="price-row">
              <span>Discount</span>
              <span className="discount">
                - ₹{parseFloat(packageData?.price_details?.discount).toFixed(2)}
              </span>
            </div>
            <div className="price-row">
              <span>Coupon Discount</span>
              <span className="discount">- ₹{couponDiscount?.toFixed(2)}</span>
            </div>
            <div className="price-row total">
              <span>Total Price</span>
              <span>₹{finalAmount}</span>
            </div>
          </div>

          <div className="terms" onClick={openTerms}>
            <img src={images?.phInfo} alt="ph-no" />
            Terms & Conditions
          </div>

          {!packageDetails?.is_subscribe && (
            <CcAvenuePayment
              packageDetails={packageDetails}
              amount={finalAmount}
              buttonText={"Proceed to Pay"}
              buttonClass={"store_pay_now"}
            />
          )}

          {shouldShowExpiryDate && (
            <div className="validity">
              Valid up to <span>{expiryDate}</span>
            </div>
          )}
        </div>
      </div>
      {showCouponList && (
        <CouponList
          userId={userId}
          package_id={package_id}
          paymentDetails={paymentDetails}
          packageData={packageData}
          couponCode={couponCode}
          setCouponCode={setCouponCode}
          applyCoupon={applyCoupon}
          handleCloseCoupon={handleCloseCoupon}
          checkApplyCouponBtnDisabled={checkApplyCouponBtnDisabled}
          handleOnChangeCouponCode={handleOnChangeCouponCode}
        />
      )}

      {showTerms && (
        <TermsAndCondition
          handleClose={closeTerms}
          terms={packageData?.terms_conditions}
        />
      )}

      {showFacultyDetails && (
        <FacultyDetail
          handleClose={closeFacultyDetails}
          FacultyDetails={FacultyDetails}
        />
      )}

      {loader && <LoaderPopUp />}
    </Fragment>
  );
};

export default PackageDetailsComponent;

import React from "react";
import "./QuestionDetailPanel.scss";
import QuestionOptions from "./questionOptions/QueOptions";
import TestProgressIndicator from "./testProgressIndicator/TestProgressIndicator";
import QuestionInfoSection from "./questionInfoSection/QueInfo";
import QuestionSolution from "./questionSolution/QueSolution";
import QuestionSwitcher from "./questionSwitcher/QueSwitcher";
import QuestionViewVideoSolution from "./questionViewVideoSolution/QuestionViewVideoSolution";

const QuestionDetailPanel = ({
  testQuestionsDetails,
  currentQuestion,
  lang,
  handlePreviousQuestionBtn,
  handleNextQuestionBtn,
  progressCount,
  scrollUp,
  scrollUp2,
  userData,
  handleVideoShow,
  videoShow,
}) => {
  return (
    <div className="right_side_content_wrapper">
      <div className="sub_section_wrapper">
        {/* <TestProgressIndicator
          progressCount={progressCount}
          currentQuestion={currentQuestion}
          testQuestionsDetails={testQuestionsDetails}
        /> */}
        <div className="timer_container d-flex justify-content-between">
          <div className="question_counter">
            <span>{currentQuestion + 1}</span>
            {` / `}
            <span>{testQuestionsDetails.length}</span>
          </div>
        </div>
        <QuestionInfoSection
          lang={lang}
          testQuestionsDetails={testQuestionsDetails}
          currentQuestion={currentQuestion}
        />

        <div
          className="sub_sub_section_wrapper_3"
          style={{ overflow: "scroll", padding: "10px" }}
        >
          {testQuestionsDetails.length > 0 && (
            <QuestionOptions
              questionData={testQuestionsDetails[currentQuestion]}
              currentQuestion={currentQuestion}
              testQuestionsDetails={testQuestionsDetails}
            />
          )}
          <QuestionSolution
            currentSolutionDetails={testQuestionsDetails[currentQuestion]}
            lang={lang}
          />

          <QuestionViewVideoSolution
            currentSolutionDetails={testQuestionsDetails[currentQuestion]}
            lang={lang}
            userData={userData}
            handleVideoShow={handleVideoShow}
            videoShow={videoShow}
          />
        </div>
      </div>
      <QuestionSwitcher
        currentQuestion={currentQuestion}
        totalQuestions={testQuestionsDetails.length}
        handlePreviousQuestionBtn={handlePreviousQuestionBtn}
        handleNextQuestionBtn={handleNextQuestionBtn}
        scrollUp={scrollUp}
        scrollUp2={scrollUp2}
      />
    </div>
  );
};

export default QuestionDetailPanel;

import { Fragment, useState, useEffect } from "react";
import "./test_content_component.scss";

///////
import TestInstructionsView from "./test_instructions_view/test_instructions_view";
import TestQuestionsView from "./test_questions_view/test_questions_view";
import userTestsExercisesAction from "../../../../../../redux/user_tests_exercises/action";
import {
  url_29,
  url_39,
  url_77,
  url_188,
  url_189,
  url_190,
  url_191,
  url_192,
  url_202,
  url_174,
  url_175,
} from "../../../../../../custom_utilities/api_services";
import axios from "axios";
import { isEmpty } from "../../../../../../custom_utilities/custom_useful_functions";

//Redux
import { connect } from "react-redux";
import uiActions from "../../../../../../redux/ui/action";
import RewardModal from "../../../../../../components/RewardModal/RewardModal";
import CoinUsesModal from "../../../../../../components/CoinUsesModal/CoinUsesModal";

const TestContentComponent = (props) => {
  let {
    history,
    testData,
    setUserTestsResultData,
    userPreference,
    startLoading,
    finishLoading,
    userData,
  } = props;
  const [state, setState] = useState({
    step: 1,
    testDetails: {},
    testSectionsDetails: {},
    testQuestionsDetails: [],
    subjecSectiontWiseTestQuestions: {},
    checkQuestion: true,
    currentQuestion: 0,
    countDownTimer: {
      hours: 0,
      minutes: 0,
      seconds: 0,
    },
    initialTime: 0,
    useTime: 0,
    currentQuestionDetails: {},
    disableQuestion: false,
    oldanswer: "",
    lang: true,
    earnedCoins: 0,
    coinPopup: false,
    reason: "RewardModal",
    coinDetailsPopup: false,
    coinUsesPoints: [],
    textResultData: {},
  });

  const [selectedSubject, setSubject] = useState("");
  const [selectedSection, setSection] = useState("");
  const [disableInput, setDisableInput] = useState(false);

  const totalTimeForSubmitTest = () => {
    const { hours, minutes, seconds } = state.countDownTimer;

    const totalSeconds = hours * 3600 + minutes * 60 + seconds;

    return state.testDetails.duration_mins * 60 - totalSeconds;
  };

  useEffect(() => {
    if (state.testQuestionsDetails.length) {
      setSubject(state.testQuestionsDetails[state.currentQuestion]["subject"]);
      setSection(
        state.testQuestionsDetails[state.currentQuestion]["section_name"]
      );
    }
  }, [state.currentQuestion, state.testQuestionsDetails]);

  useEffect(() => {
    getTestDetails();
    getTestSectionsDetails();
    coinUsesApi();
  }, []);

  useEffect(() => {
    if (state.step === 2) {
      createCountDownTimer(state.testDetails.duration_mins);
    }
    return () => {
      clearInterval(timeInterval);
    };
  }, [state.step]);

  const formatData =
    state.testDetails.format ==
    "MAIN PATTERN TEST  [20 SINGLE, 10 NUMERIC] EACH SUBJECT"
      ? "MAIN"
      : state.testDetails.format == "NEET NEW PATTERN 2021"
      ? "NEET"
      : "OTHER";

  const handleSubjectChange = (subject) => {
    let { testQuestionsDetails } = state;

    const section = Object.keys(
      state.subjecSectiontWiseTestQuestions[subject]
    )[0];
    setSubject(subject);
    setSection(section);
    const currentQuestion =
      state.subjecSectiontWiseTestQuestions[subject][section][0]["matchIndex"];
    setState((prevState) => ({
      ...prevState,
      currentQuestion,
    }));

    let cq = currentQuestion;

    getCurrentQuestionDetails(
      testQuestionsDetails[cq].paper_id,
      testQuestionsDetails[cq].qid,
      testQuestionsDetails[cq].subject,
      testQuestionsDetails[cq].section_name,
      formatData
    );
    scrollUp(currentQuestion);
  };

  const scrollUp = (currentQuestion) => {
    var element = document.getElementsByClassName(
      "questions_serial_number_collection"
    )[0];
    element.scrollTo({
      top: `${(currentQuestion + 1) * 80}`,
      behavior: "smooth",
    });
  };

  const handleSectionChange = (section) => {
    let { testQuestionsDetails } = state;

    setSection(section);
    const currentQuestion =
      state.subjecSectiontWiseTestQuestions[selectedSubject][section][0][
        "matchIndex"
      ];

    setState((prevState) => ({
      ...prevState,
      currentQuestion,
    }));
    let cq = currentQuestion;

    getCurrentQuestionDetails(
      testQuestionsDetails[cq].paper_id,
      testQuestionsDetails[cq].qid,
      testQuestionsDetails[cq].subject,
      testQuestionsDetails[cq].section_name,
      formatData
    );

    scrollUp(currentQuestion);
  };

  let timeInterval;
  const createCountDownTimer = (duration) => {
    let useTime = state.useTime;
    useTime = useTime * 1000;

    //duration  is in minutes, first convert it to milliseconds
    let countdownTime = duration * 60 * 1000 + new Date().getTime();
    countdownTime = countdownTime - useTime;

    timeInterval = setInterval(() => {
      let now = new Date().getTime();
      let distance = countdownTime - now;
      if (distance < 0) {
        alert("Time is up. Your test will be auto submitted");
        handleSubmitTest(state.currentQuestionDetails);
        handleLastQuestionSave();
        clearInterval(timeInterval);
        //hidemyend    //handleSubmitTest();
      }
      let hours = Math.floor(
        (distance % (1000 * 60 * 60 * 60)) / (1000 * 60 * 60)
      );
      let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((distance % (1000 * 60)) / 1000);
      setState((prevState) => ({
        ...prevState,
        countDownTimer: {
          ...prevState.countDownTimer,
          hours,
          minutes,
          seconds,
        },
      }));
    }, 1000);
  };

  const getTestDetails = () => {
    setState((prevState) => ({ ...prevState, testDetails: testData }));
  };

  const getTestSectionsDetails = async () => {
    let data = {
      paper_id: testData.paper_id,
    };

    startLoading();
    try {
      const response = await axios.post(url_29, data);
      if (response.data.status === 200) {
        setState((prevState) => ({
          ...prevState,
          testSectionsDetails: response.data.data,
        }));
        finishLoading();
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const getTestQuestionsList = async (testDetails) => {
    let data = {
      paper_id: testDetails.paper_id,
    };
    startLoading();
    try {
      const response = await axios.post(url_188, data);
      if (response.data.status === 200) {
        finishLoading();
        return {
          result: 1,
          data: { apiData: response.data },
        };
      } else {
        finishLoading();
        return {
          result: 0,
          data: { apiData: {} },
        };
      }
    } catch (error) {
      console.log(error);
      finishLoading();
      return {
        result: 0,
        data: { apiData: {} },
      };
    }
  };

  const getCurrentQuestionDetails = async (
    paper_id,
    qid,
    subject,
    section,
    format
  ) => {
    const data = {
      paper_id,
      qid,
      subject,
      section,
      format,
    };

    try {
      const response = await axios.post(url_189, data);

      // setTimeout(() => {
      if (response.data.status === 200) {
        setState((prevState) => ({
          ...prevState,
          currentQuestionDetails: response.data.data[0],
          oldanswer: response.data.data[0].oldanswer,
        }));
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const subjectWiseData = (questions) => {
    const subjectSectionWiseData = {};
    for (let i = 0; i < questions.length; i++) {
      questions[i]["matchIndex"] = i;
      if (!subjectSectionWiseData[questions[i]["subject"]]) {
        subjectSectionWiseData[questions[i]["subject"]] = {
          [questions[i]["section_name"]]: [questions[i]],
        };
      } else if (
        subjectSectionWiseData?.[questions?.[i]?.["subject"]] &&
        !subjectSectionWiseData?.[questions?.[i]?.["subject"]]?.[
          questions?.[i]?.["section_name"]
        ]
      ) {
        subjectSectionWiseData[questions[i]["subject"]][
          questions[i]["section_name"]
        ] = [questions[i]];
      } else {
        subjectSectionWiseData[questions[i]["subject"]][
          questions[i]["section_name"]
        ].push(questions[i]);
      }
    }
    return subjectSectionWiseData;
  };

  const getNowTime = () => {
    let d = new Date();
    return d.getTime();
  };

  const deleteOldDateBeforeStartingTest = async () => {
    try {
      const data = {
        user_id: userData.user_id,
        paper_id: state.testDetails.paper_id,
      };

      const response = await axios.post(url_202, data);

      if (response.data.status !== 200) {
        alert("Error Occured, Please try again");
      }
    } catch (err) {
      console.log(err);
    }
  };

  //exitTimeCurrentOption
  const startBtn = async () => {
    let testSectionsDetails = { ...state.testSectionsDetails };
    let testDetails = { ...state.testDetails };

    if (!testSectionsDetails?.testlist?.is_resume) {
      await deleteOldDateBeforeStartingTest();
    }

    if (!isEmpty(testSectionsDetails) && !isEmpty(testDetails)) {
      let returnedResponse = await getTestQuestionsList(testDetails);
      const _subjecSectiontWiseTestQuestions = subjectWiseData(
        returnedResponse.data.apiData.data
      );

      const response = await checkIsResumeTest(returnedResponse.data.apiData);

      if (returnedResponse.result) {
        returnedResponse.data.apiData.data.forEach((element) => {
          element.isReview = false;
          // element.isSkipped = false;
          element.isAttempted = false;
          element.isAnswerGuessed = false;
          element.totalTimeTaken = 0;
          element.isAnswerCorrect = 0;
          element.correctAnswerIdCollection = [];
          element.wrongAnswerIdCollection = [];
          element.userAnswerCollection = [];
          // element.q_text = element.q_text.replaceAll(
          //   "ckfinder",
          //   "http://quizmaster.motion.ac.in/ckfinder"
          // );
          element.q_text = element.q_text
            .replaceAll(
              'src="ckfinder',
              'src="http://quizmaster.motion.ac.in/ckfinder'
            )
            .replaceAll(
              'SRC="ckfinder',
              'SRC="http://quizmaster.motion.ac.in/ckfinder'
            )
            .replaceAll(
              'src="/ckfinder',
              'src="http://quizmaster.motion.ac.in/ckfinder'
            )
            .replaceAll(
              'SRC="/ckfinder',
              'SRC="http://quizmaster.motion.ac.in/ckfinder'
            )
            .replaceAll(
              'SRC="ckfinder',
              'SRC="http://quizmaster.motion.ac.in/ckfinder'
            )
            .replaceAll(
              'SRC="./ckfinder',
              'SRC="http://quizmaster.motion.ac.in/ckfinder'
            )
            .replaceAll(
              'SRC= "ckfinder',
              'SRC="http://quizmaster.motion.ac.in/ckfinder'
            );
          element.option?.forEach((opt) => {
            opt.option = opt.option
              .replaceAll(
                'src="ckfinder',
                'src="http://quizmaster.motion.ac.in/ckfinder'
              )
              .replaceAll(
                'SRC="ckfinder',
                'SRC="http://quizmaster.motion.ac.in/ckfinder'
              )
              .replaceAll(
                'src="/ckfinder',
                'src="http://quizmaster.motion.ac.in/ckfinder'
              )
              .replaceAll(
                'SRC="/ckfinder',
                'SRC="http://quizmaster.motion.ac.in/ckfinder'
              )
              .replaceAll(
                'SRC="ckfinder',
                'SRC="http://quizmaster.motion.ac.in/ckfinder'
              )
              .replaceAll(
                'SRC="./ckfinder',
                'SRC="http://quizmaster.motion.ac.in/ckfinder'
              )
              .replaceAll(
                'SRC= "ckfinder',
                'SRC="http://quizmaster.motion.ac.in/ckfinder'
              );
          });
          //
          //      "src=\"ckfinder", "src=\"http://quizmaster.motion.ac.in/ckfinder"
          //    )
          //    .replaceAll(
          //      "SRC=\"ckfinder", "SRC=\"http://quizmaster.motion.ac.in/ckfinder"
          //    )
          //    .replaceAll(
          //      "src=\"/ckfinder", "src=\"http://quizmaster.motion.ac.in/ckfinder"
          // )
          // .replaceAll(
          //      "SRC=\"/ckfinder", "SRC=\"http://quizmaster.motion.ac.in/ckfinder"
          //    )
          //    .replaceAll(
          //      "SRC=\"ckfinder", "SRC=\"http://quizmaster.motion.ac.in/ckfinder"
          //    )
          //    .replaceAll(
          //      "SRC=\"./ckfinder", "SRC=\"http://quizmaster.motion.ac.in/ckfinder"
          //    ).replaceAll(
          //      "SRC= \"ckfinder", "SRC=\"http://quizmaster.motion.ac.in/ckfinder"
          //    );
        });
        let time = getNowTime();

        setState((prevState) => ({
          ...prevState,
          step: 2,
          testQuestionsDetails: returnedResponse.data.apiData.data,
          subjecSectiontWiseTestQuestions: _subjecSectiontWiseTestQuestions,
          initialTime: time,
          useTime: returnedResponse.data.apiData.total_time,
          currentQuestion: response,
        }));

        getCurrentQuestionDetails(
          returnedResponse?.data?.apiData?.data[response]?.paper_id,
          returnedResponse?.data?.apiData?.data[response]?.qid,
          returnedResponse?.data?.apiData?.data[response]?.subject,
          returnedResponse?.data?.apiData?.data[response]?.section_name,
          formatData
        );
      } else {
        alert("No test questions found");
      }
    } else {
      alert("You don't have Test Details");
    }
  };

  const checkIsResumeTest = async (test) => {
    if (test.userGivenLastQuestionId !== 0) {
      const indexOfLastAttemptedQue = test.data.findIndex(
        (element) => element.qid == test.userGivenLastQuestionId
      );

      if (indexOfLastAttemptedQue == test.data.length - 1) {
        return indexOfLastAttemptedQue;
      }

      return indexOfLastAttemptedQue + 1;
    } else {
      return 0;
    }
  };

  const getCalculatedTime = (initialTime) => {
    let d = new Date();
    let time = d.getTime();

    let calculatedTime = Math.round((time - initialTime) / 1000);

    return calculatedTime;
  };
  const handlePreviousQuestionBtn = (cq) => {
    let { currentQuestion, initialTime, testQuestionsDetails } = state;
    let calculatedTime = getCalculatedTime(initialTime);

    testQuestionsDetails[currentQuestion].totalTimeTaken = calculatedTime;
    let time = getNowTime();
    if (currentQuestion >= 1) {
      setState((prevState) => ({
        ...prevState,
        currentQuestion: currentQuestion - 1,
        testQuestionsDetails: testQuestionsDetails,
        initialTime: time,
      }));

      getCurrentQuestionDetails(
        testQuestionsDetails[cq].paper_id,
        testQuestionsDetails[cq].qid,
        testQuestionsDetails[cq].subject,
        testQuestionsDetails[cq].section_name,
        formatData
      );

      window.scrollTo(0, 0);
    }
  };

  //save prticular question
  const handleSaveQuestion = async (cq) => {
    let {
      currentQuestion,
      initialTime,
      testQuestionsDetails,
      testDetails,
      currentQuestionDetails,
      oldanswer,
    } = state;

    console.log("old answer in handleSaveQuesiton", oldanswer);

    let calculatedTime = getCalculatedTime(initialTime);

    let crntQuestionDetail = testQuestionsDetails[currentQuestion];

    let data = {};

    if (crntQuestionDetail.answer_type == "Integer Correct") {
      data = {
        negative_marks: crntQuestionDetail.negative_marks,
        option_int: oldanswer?.length ? oldanswer : "",

        paper_id: crntQuestionDetail.paper_id,
        partial_marks: crntQuestionDetail.partial_marks,
        positive_marks: crntQuestionDetail.positive_marks,
        qid: crntQuestionDetail.qid,
        subject: crntQuestionDetail.subject,
        time: calculatedTime ? calculatedTime : 0,
        topic_name: crntQuestionDetail.topic_name,
        toughness: crntQuestionDetail.toughness,
        format: formatData,
        section: crntQuestionDetail.section_name,
        lang_id: state.lang ? 1 : 2,
      };
    } else {
      data = {
        negative_marks: crntQuestionDetail.negative_marks,

        paper_id: crntQuestionDetail.paper_id,
        partial_marks: crntQuestionDetail.partial_marks,
        positive_marks: crntQuestionDetail.positive_marks,
        qid: crntQuestionDetail.qid,
        subject: crntQuestionDetail.subject,
        time: calculatedTime ? calculatedTime : 0,
        topic_name: crntQuestionDetail.topic_name,
        toughness: crntQuestionDetail.toughness,
        format: formatData,
        section: crntQuestionDetail.section_name,
        lang_id: state.lang ? 1 : 2,
        option_id: crntQuestionDetail.userAnswerCollection.length
          ? crntQuestionDetail.userAnswerCollection
          : oldanswer.length
          ? [oldanswer]
          : [],
      };
    }

    startLoading();
    const response = await axios.post(url_190, data);

    if (response.data.status === 200) {
      finishLoading();

      if (currentQuestion !== 89) {
        getCurrentQuestionDetails(
          testQuestionsDetails[cq]?.paper_id,
          testQuestionsDetails[cq]?.qid,
          testQuestionsDetails[cq]?.subject,
          testQuestionsDetails[cq]?.section_name,
          formatData
        );
      }
    } else {
      finishLoading();
      alert("Your Previous Question hasn't been Saved!");
      if (currentQuestion !== 89) {
        getCurrentQuestionDetails(
          testQuestionsDetails[cq].paper_id,
          testQuestionsDetails[cq].qid,
          testQuestionsDetails[cq].subject,
          testQuestionsDetails[cq].section_name,
          formatData
        );
      }
    }
  };

  const handleNextQuestionBtn = (cq, currentQuestionDetails) => {
    let { currentQuestion, initialTime, testQuestionsDetails } = state;
    let calculatedTime = getCalculatedTime(initialTime);

    if (testQuestionsDetails[currentQuestion].userAnswerCollection.length > 0) {
      testQuestionsDetails[currentQuestion].isAttempted = true;
      testQuestionsDetails[currentQuestion].isSkipped = false;
    } else {
      testQuestionsDetails[currentQuestion].isAttempted = false;
      testQuestionsDetails[currentQuestion].isSkipped = true;
    }

    let type = currentQuestionDetails?.answer_type?.toLowerCase();

    if (type === "integer correct") {
      if (state.oldanswer?.length > 0) {
        testQuestionsDetails[currentQuestion].isAttempted = true;
        testQuestionsDetails[currentQuestion].isSkipped = false;
      } else {
        testQuestionsDetails[currentQuestion].isAttempted = false;
        testQuestionsDetails[currentQuestion].isSkipped = true;
      }
    }

    let time = getNowTime();

    setState((prevState) => ({
      ...prevState,
      currentQuestion: currentQuestion + 1,
      testQuestionsDetails: testQuestionsDetails,
      initialTime: time,
    }));

    handleSaveQuestion(cq);

    window.scrollTo(0, 0);
  };

  // const handleSingleSelectAnswer = (event, optionId) => {
  //   let { checked, value } = event.target;

  //   let { currentQuestion, testQuestionsDetails } = state;

  //   if (checked) {
  //     testQuestionsDetails[currentQuestion].userAnswerCollection[0] = optionId;

  //     testQuestionsDetails[currentQuestion][0] = optionId;

  //     if (
  //       testQuestionsDetails[currentQuestion].correctAnswerIdCollection.indexOf(
  //         optionId
  //       ) === -1
  //     ) {
  //       //if you not find the answer in backendCorrect answer array
  //       testQuestionsDetails[currentQuestion].wrongAnswerIdCollection[0] =
  //         optionId;
  //       testQuestionsDetails[currentQuestion].correctAnswerIdCollection = [];
  //       testQuestionsDetails[currentQuestion].isAnswerCorrect = 0;
  //     } else {
  //       testQuestionsDetails[currentQuestion].correctAnswerIdCollection[0] =
  //         optionId;
  //       testQuestionsDetails[currentQuestion].wrongAnswerIdCollection = [];
  //       testQuestionsDetails[currentQuestion].isAnswerCorrect = 1;
  //     }
  //   } else {
  //     testQuestionsDetails[currentQuestion].userAnswerCollection = [];
  //     testQuestionsDetails[currentQuestion].wrongAnswerIdCollection = [];
  //     testQuestionsDetails[currentQuestion].correctAnswerIdCollection = [];
  //     testQuestionsDetails[currentQuestion].isAnswerCorrect = 0;
  //   }

  //   setState((prevState) => ({
  //     ...prevState,
  //     testQuestionsDetails,
  //   }));
  // };

  const handleSingleSelectAnswer = (event, optionId) => {
    let { checked, value } = event.target;

    let { currentQuestion, testQuestionsDetails } = state;

    if (checked) {
      // testQuestionsDetails[currentQuestion].isAttempted = true;

      testQuestionsDetails[currentQuestion].userAnswerCollection[0] = optionId;

      testQuestionsDetails[currentQuestion][0] = optionId;

      testQuestionsDetails[currentQuestion].is_option = 1;

      if (
        testQuestionsDetails[currentQuestion].correctAnswerIdCollection.indexOf(
          optionId
        ) === -1
      ) {
        //if you not find the answer in backendCorrect answer array
        testQuestionsDetails[currentQuestion].wrongAnswerIdCollection[0] =
          optionId;
        testQuestionsDetails[currentQuestion].correctAnswerIdCollection = [];
        testQuestionsDetails[currentQuestion].isAnswerCorrect = 0;
      } else {
        testQuestionsDetails[currentQuestion].correctAnswerIdCollection[0] =
          optionId;
        testQuestionsDetails[currentQuestion].wrongAnswerIdCollection = [];
        testQuestionsDetails[currentQuestion].isAnswerCorrect = 1;
      }
    } else {
      // testQuestionsDetails[currentQuestion].isAttempted = false;
      testQuestionsDetails[currentQuestion].userAnswerCollection = [];
      testQuestionsDetails[currentQuestion].wrongAnswerIdCollection = [];
      testQuestionsDetails[currentQuestion].correctAnswerIdCollection = [];
      testQuestionsDetails[currentQuestion].isAnswerCorrect = 0;
      testQuestionsDetails[currentQuestion].is_option = 2;
    }

    setState((prevState) => ({
      ...prevState,
      testQuestionsDetails,
      oldanswer: "",
    }));
  };

  // const handleMultiSelectAnswer = (_event, optionId, checked) => {
  //   let { currentQuestion, testQuestionsDetails } = state;

  //   let findedIndex = testQuestionsDetails[
  //     currentQuestion
  //   ].userAnswerCollection.findIndex((element) => {
  //     return element === optionId;
  //   });

  //   if (findedIndex === -1) {
  //     testQuestionsDetails[currentQuestion].userAnswerCollection.splice(
  //       testQuestionsDetails[currentQuestion].userAnswerCollection.length,
  //       0,
  //       optionId
  //     );
  //   } else {
  //     testQuestionsDetails[currentQuestion].userAnswerCollection.splice(
  //       findedIndex,
  //       1
  //     );
  //   }

  //   setState((prevState) => ({ ...prevState, testQuestionsDetails }));
  // };

  const handleMultiSelectAnswer = (_event, optionId, checked) => {
    let { currentQuestion, testQuestionsDetails } = state;

    let findedIndex = testQuestionsDetails[
      currentQuestion
    ].userAnswerCollection.findIndex((element) => {
      return element === optionId;
    });

    if (findedIndex === -1) {
      testQuestionsDetails[currentQuestion].userAnswerCollection.splice(
        testQuestionsDetails[currentQuestion].userAnswerCollection.length,
        0,
        optionId
      );
    } else {
      testQuestionsDetails[currentQuestion].userAnswerCollection.splice(
        findedIndex,
        1
      );
    }

    // if (testQuestionsDetails[currentQuestion].userAnswerCollection.length) {
    //   testQuestionsDetails[currentQuestion].isAttempted = true;
    // } else {
    //   testQuestionsDetails[currentQuestion].isAttempted = false;
    // }

    // if (testQuestionsDetails[currentQuestion].wrongAnswerIdCollection.length) {
    //   testQuestionsDetails[currentQuestion].isAnswerCorrect = false;
    // }

    setState((prevState) => ({ ...prevState, testQuestionsDetails }));
  };

  const handleInputFieldChange = (event) => {
    let { value } = event.target;
    value = value.replace(/[^-\d._e]/g, "");
    let {
      currentQuestion,
      testQuestionsDetails,
      checkQuestion,
      currentQuestionDetails,
    } = state;

    state.oldanswer = value.trim();

    setState((prevState) => ({
      ...prevState,
      ...prevState.countDownTimer,
      testQuestionsDetails,
      oldanswer: value,
    }));
  };

  const reviewQuestion = async (currentQuestion) => {
    const { paper_id, qid, isAnswerGuessed } = currentQuestion;
    let data = {
      paper_id: paper_id,
      reference_id: qid,
      status: isAnswerGuessed ? 1 : 0,
      type: 3,
    };
    const response = await axios.post(url_77, data);
  };

  const handleReview = () => {
    let { currentQuestion, testQuestionsDetails } = state;

    testQuestionsDetails[currentQuestion].isAnswerGuessed =
      !testQuestionsDetails[currentQuestion].isAnswerGuessed;

    setState((prevState) => ({ ...prevState, testQuestionsDetails }));
    reviewQuestion(testQuestionsDetails[currentQuestion]);
  };

  const handleSaved = () => {
    let { currentQuestion, testQuestionsDetails, testDetails } = state;

    testQuestionsDetails[currentQuestion].is_saved =
      !testQuestionsDetails[currentQuestion].is_saved;

    setState((prevState) => ({ ...prevState, testQuestionsDetails }));
    if (testQuestionsDetails[currentQuestion].is_saved) {
      let data = {
        type: 3, // 3=>test
        reference_id: testQuestionsDetails[currentQuestion].qid,
        status: 1, // 1=> save
      };
      bookmarkApi(data);
    } else {
      let data = {
        type: 3, // 3=>test
        reference_id: testQuestionsDetails[currentQuestion].qid,
        status: 0, // 0=> unsave
      };
      bookmarkApi(data);
    }
  };

  const bookmarkApi = async (data) => {
    try {
      const response = await axios.post(url_39, data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleGuessedAnswer = (event) => {
    let { checked } = event.target;
    let { currentQuestion, testQuestionsDetails } = state;

    testQuestionsDetails[currentQuestion].isAnswerGuessed = checked;

    setState((prevState) => ({ ...prevState, testQuestionsDetails }));
  };

  // const getNewAnalyticsApi = async (testDetails) => {
  //   const { paper_id, test_id } = testDetails;
  //   const data = {
  //     paper_id,
  //   };

  //   const response = await axios.post(url_192, data);
  //   if (response.data.status === 200) {
  //     return 1;
  //   } else return 0;
  // };

  const getNewAnalyticsApi = async (testDetails) => {
    let data = {
      paper_id: testDetails.paper_id,
    };
    startLoading();
    try {
      const response = await axios.post(url_192, data);
      if (response.data.status === 200) {
        setState((prev) => {
          return {
            ...prev,
            textResultData: response.data.data1,
          };
        });
        console.log("resultData", response.data.data);
        addCoinsOnSubmission(
          response.data.data1.correct_answer,
          response.data.data1.incorrect_answer
        );
        finishLoading();
      }
    } catch (error) {
      console.log(error);
      finishLoading();
    }
  };

  const addCoinsOnSubmission = async (correct_ans, wrong_ans) => {
    const data = {
      type: "Attending_EXAMS",
      wrong_ans: wrong_ans,
      correct_ans: correct_ans,
      paper_type: "EXAMS",
      paper_id: state.testDetails.paper_id,
    };
    try {
      const response = await axios.post(url_174, data);
      console.log("response.data.coints", response.data);
      if (response.data.status === 200) {
        if (response.data.data !== "MCoins Already Credited...!") {
          console.log(
            "------------------------------------",
            response.data.data.coins
          );
          setState((prev) => {
            return {
              ...prev,
              earnedCoins: response.data.data.coins,
              coinPopup: response.data.data.coins > 0 ? true : false,
              reason: response.data.data.description,
            };
          });
          if (response.data.data.coins === 0) {
            console.log(
              "going inside of if response.data.data.coins === 0",
              response.data.data.coins
            );
            console.log("coming inside of coins ===== 0");
            history.push({
              pathname: "/exam_summary",
            });
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const coinUsesApi = async () => {
    try {
      const response = await axios.get(url_175);
      if (response.data.status === 200) {
        console.log(response.data);
        setState((prevState) => {
          return {
            ...prevState,
            coinUsesPoints: [...response.data.data],
          };
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleQuestionJump = (questionNumber) => {
    let { testQuestionsDetails } = state;

    const { testDetails, currentQuestion, currentQuestionDetails } = state;

    if (testQuestionsDetails[currentQuestion].userAnswerCollection.length > 0) {
      testQuestionsDetails[currentQuestion].isAttempted = true;
      testQuestionsDetails[currentQuestion].isSkipped = false;
    } else {
      testQuestionsDetails[currentQuestion].isAttempted = false;
      testQuestionsDetails[currentQuestion].isSkipped = true;
    }

    let type = currentQuestionDetails?.answer_type?.toLowerCase();

    if (type === "integer correct") {
      if (state.oldanswer?.length > 0) {
        testQuestionsDetails[currentQuestion].isAttempted = true;
        testQuestionsDetails[currentQuestion].isSkipped = false;
      } else {
        testQuestionsDetails[currentQuestion].isAttempted = false;
        testQuestionsDetails[currentQuestion].isSkipped = true;
      }
    }

    setState((prevState) => ({
      ...prevState,
      currentQuestion: questionNumber,
    }));

    getCurrentQuestionDetails(
      testQuestionsDetails[questionNumber].paper_id,
      testQuestionsDetails[questionNumber].qid,
      testQuestionsDetails[questionNumber].subject,
      testQuestionsDetails[questionNumber].section_name,
      formatData
    );
  };

  const handleSubmitTest = async () => {
    let { testDetails, testQuestionsDetails } = state;

    let questionArr = [];

    testQuestionsDetails.forEach((element) => {
      if (element.answer_type.toLowerCase() === "single correct") {
        let data = {
          qid: element.qid,
          type_id: element.type_id,
          is_saved: element.is_saved,
          total_time: element.totalTimeTaken,
          is_attempted: element.isAttempted,
          is_AnswerCorrect: element.isAnswerCorrect,
          is_GessTypeAnswer: element.isAnswerGuessed,
          paragraph_answer: element.paragraph_answer,
          integer_answer: element.integer_answer,
          positive_marks: element.positive_marks,
          negative_marks: element.negative_marks,
          partial_marks: element.partial_marks,
          CorrectAnswerId: element.correctAnswerIdCollection,
          WrongeAnswerId: element.wrongAnswerIdCollection,
          answer: element.userAnswerCollection,
          toughness_id: element.toughness_id,
          subject: element.subject,
          topic_id: element.topic_id,
        };
        if (!data.type_id) {
          data.type_id = 6;
        }
        questionArr.push(data);
      } else if (element.answer_type.toLowerCase() === "multiple correct") {
        let data = {
          qid: element.qid,
          type_id: element.type_id,
          is_saved: element.is_saved,
          total_time: element.totalTimeTaken,
          is_attempted: element.isAttempted,
          is_AnswerCorrect: element.isAnswerCorrect,
          is_GessTypeAnswer: element.isAnswerGuessed,
          paragraph_answer: element.paragraph_answer,
          integer_answer: element.integer_answer,
          positive_marks: element.positive_marks,
          negative_marks: element.negative_marks,
          partial_marks: element.partial_marks,
          CorrectAnswerId: element.correctAnswerIdCollection,
          WrongeAnswerId: element.wrongAnswerIdCollection,
          answer: element.userAnswerCollection,
          toughness_id: element.toughness_id,
          subject: element.subject,
          topic_id: element.topic_id,
        };
        if (!data.type_id) {
          data.type_id = 6;
        }
        questionArr.push(data);
      } else if (element.answer_type.toLowerCase() === "integer correct") {
        let data = {
          qid: element.qid,
          type_id: element.type_id,
          is_saved: element.is_saved,
          total_time: element.totalTimeTaken,
          is_attempted: element.isAttempted,
          paragraph_answer: element.paragraph_answer,
          integer_answer: element.integer_answer,
          is_AnswerCorrect: element.isAnswerCorrect,
          is_GessTypeAnswer: element.isAnswerGuessed,
          positive_marks: element.positive_marks,
          negative_marks: element.negative_marks,
          partial_marks: element.partial_marks,
          CorrectAnswerId: element.correctAnswerIdCollection,
          WrongeAnswerId: element.wrongAnswerIdCollection,
          answer: element.userAnswerCollection,
          toughness_id: element.toughness_id,
          subject: element.subject,
          topic_id: element.topic_id,
        };
        if (!data.type_id) {
          data.type_id = 6;
        }
        questionArr.push(data);
      }
    });
    startLoading();
    let returnedResponse = await submitTestApi(
      userPreference,
      testDetails,
      questionArr
    );
    if (returnedResponse.result) {
      // const response = await getNewAnalyticsApi(testDetails);
      getNewAnalyticsApi(testDetails);
      // if (response) {
      //   setUserTestsResultData(testDetails);
      //   history.push({
      //     pathname: "/exam_summary",
      //     state: { tab: "exam" },
      //   });
      //   finishLoading();
      // }
    } else {
      finishLoading();
      alert(returnedResponse.data.message);
    }
  };

  const submitTestApi = async (_userPreference, testDetails, _questionArr) => {
    let requestPayload = {
      paper_id: testDetails.paper_id,
      time: totalTimeForSubmitTest(),
    };

    try {
      const response = await axios.post(url_191, requestPayload);
      if (response.data.status === 200) {
        return {
          result: 1,
          data: {
            message: response.data.message,
          },
        };
      } else {
        return {
          result: 0,
          data: {
            message: response.data.message,
          },
        };
      }
    } catch (error) {
      return {
        result: 0,
        data: {
          message: "Something went wrong!!!",
        },
      };
    }
  };

  const handleLastQuestionSave = async () => {
    let {
      currentQuestion,
      initialTime,
      testQuestionsDetails,
      testDetails,
      oldanswer,
    } = state;

    let calculatedTime = getCalculatedTime(initialTime);

    let crntQuestionDetail = testQuestionsDetails[currentQuestion];

    let formatData =
      state.testDetails.target_type == "JEE (M)"
        ? "MAIN"
        : state.testDetails?.target_type == "NEET"
        ? "NEET"
        : "OTHER";

    let data = {};
    if (crntQuestionDetail.answer_type == "Integer Correct") {
      data = {
        negative_marks: parseInt(crntQuestionDetail.negative_marks),

        option_int: oldanswer?.length ? oldanswer : "",

        paper_id: crntQuestionDetail.paper_id,
        partial_marks: parseInt(crntQuestionDetail.partial_marks),
        positive_marks: parseInt(crntQuestionDetail.positive_marks),
        qid: crntQuestionDetail.qid,
        subject: crntQuestionDetail.subject,
        time: calculatedTime ? calculatedTime : 0,
        topic_name: crntQuestionDetail.topic_name,
        toughness: crntQuestionDetail.toughness,

        format: formatData,
        section: crntQuestionDetail.section_name,

        test_id: testDetails.test_id,
      };
    } else {
      data = {
        negative_marks: parseInt(crntQuestionDetail.negative_marks),

        option_id: crntQuestionDetail?.userAnswerCollection
          ? crntQuestionDetail.userAnswerCollection
          : state.oldanswer.length
          ? [state.oldanswer]
          : [],

        paper_id: crntQuestionDetail.paper_id,
        partial_marks: parseInt(crntQuestionDetail.partial_marks),
        positive_marks: parseInt(crntQuestionDetail.positive_marks),
        qid: crntQuestionDetail.qid,
        subject: crntQuestionDetail.subject,
        time: calculatedTime ? calculatedTime : 0,
        topic_name: crntQuestionDetail.topic_name,
        toughness: crntQuestionDetail.toughness,

        format: formatData,
        section: crntQuestionDetail.section_name,

        test_id: testDetails.test_id,
      };
    }

    startLoading();

    const response = await axios.post(url_190, data);

    if (response.data.status === 200) {
      finishLoading();
    } else {
      finishLoading();
    }
  };

  const handleRedirectBtn = () => {
    history.push({
      pathname: "/pyq",
      state: { tab: "pyq" },
    });
  };

  const handleChangeLanguage = () => {
    setState((prevState) => ({
      ...prevState,
      lang: !prevState.lang,
    }));
  };

  const renderView = (step) => {
    switch (step) {
      case 1:
        return (
          <TestInstructionsView
            testDetails={state.testDetails}
            testSectionsDetails={state.testSectionsDetails}
            startBtn={startBtn}
            handleRedirectBtn={handleRedirectBtn}
          />
        );
      case 2:
        return (
          <TestQuestionsView
            testQuestionsDetails={state.testQuestionsDetails}
            subjecSectiontWiseTestQuestions={
              state.subjecSectiontWiseTestQuestions
            }
            selectedSubject={selectedSubject}
            selectedSection={selectedSection}
            handleSubjectChange={handleSubjectChange}
            handleSectionChange={handleSectionChange}
            currentQuestion={state.currentQuestion}
            countDownTimer={state.countDownTimer}
            handlePreviousQuestionBtn={handlePreviousQuestionBtn}
            handleNextQuestionBtn={handleNextQuestionBtn}
            handleSaveQuestion={handleSaveQuestion}
            handleSingleSelectAnswer={handleSingleSelectAnswer}
            handleMultiSelectAnswer={handleMultiSelectAnswer}
            handleInputFieldChange={handleInputFieldChange}
            handleReview={handleReview}
            handleSaved={handleSaved}
            handleGuessedAnswer={handleGuessedAnswer}
            handleQuestionJump={handleQuestionJump}
            handleSubmitTest={handleSubmitTest}
            currentQuestionDetails={state.currentQuestionDetails}
            checkQuestion={state.checkQuestion}
            userId={userData.user_id}
            startLoading={startLoading}
            finishLoading={finishLoading}
            setDisableInput={setDisableInput}
            disableInput={disableInput}
            examFormat={state?.testDetails?.format}
            setState={setState}
            handleLastQuestionSave={handleLastQuestionSave}
            handleChangeLanguage={handleChangeLanguage}
            lang={state.lang}
            testDetails={state.testDetails}
            oldanswer={state.oldanswer}
          />
        );

      default:
        return null;
    }
  };

  return (
    <Fragment>
      <RewardModal
        show={state.coinPopup}
        onHide={() => {
          history.push({ pathname: "/exam_summary" });
        }}
        earnMoreHandler={() =>
          setState((prev) => {
            return { ...prev, coinDetailsPopup: true, coinPopup: false };
          })
        }
        earnedCoins={state.earnedCoins}
        reason={state.reason}
      />
      <CoinUsesModal
        show={state.coinDetailsPopup}
        onHide={() => {
          history.push({ pathname: "/exam_summary" });
        }}
        data={state.coinUsesPoints}
      />
      <div className="test_content_component_wrapper">
        <div className="test_content_component_inner_wrapper">
          {renderView(state.step)}
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    testData: state.userTestsExercisesData.testData,
    userPreference: state.userPreference,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
    setUserTestsResultData: (payload) => {
      dispatch(userTestsExercisesAction.setUserTestsResultData(payload));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TestContentComponent);

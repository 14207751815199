import React, { useState, useEffect } from "react";
import axios from "axios";
import { isEmpty } from "../../../../../custom_utilities/custom_useful_functions";
import "./PredictCollegeContentComponent.scss";
import LoaderPopUp from "../../../../../components/loader/loader";
import images from "../../../../../utilities/images/images";

const PredictCollegeContentComponent = (props) => {
  const {
    startLoading,
    finishLoading,
    loader,
    allIndiaRank,
    expectedRank,
    target,
    parentTarget,
    studentTarget,
  } = props;

  const [state, setState] = useState({
    collegeDetails: [],
    states: [],
    stateSelected: "",
    categorySelected: "",
  });
  const { collegeDetails, states, stateSelected, categorySelected } = state;

  const categories = ["general", "obc", "sc", "st"];

  useEffect(() => {
    getCollegeDetails();
    getStates();
  }, []);

  useEffect(() => {
    getCollegeDetails(state.categorySelected, state.selectedState);
  }, [state.categorySelected, state.selectedState]);

  const getCollegeDetails = async (category, state) => {
    startLoading();

    // const cast = (await category) !== "" ? category : "general";

    const studentTargetId = studentTarget === 3 ? "NEET" : "JEE";

    console.log(
      "studentTargetId",
      studentTargetId,
      "parentTargetId",
      parentTarget
    );

    console.log("parentTargetIdparentTargetId", studentTargetId);

    const data = {
      target: parentTarget?.length > 0 ? parentTarget : studentTargetId,
      target: parentTarget,
      rank: allIndiaRank,
      category,
      state,
    };

    console.log("tesing getCollegeDetails", data);

    try {
      const response = await axios.post(
        "https://learning.motion.ac.in/motioneducation/api/StudentCollegePredictor",
        data
      );

      if (response.data.status === 200) {
        setState((prevState) => ({
          ...prevState,
          collegeDetails: response.data.college_predictor,
        }));
        finishLoading();
      }
    } catch (error) {
      //   finishLoading();
      console.log(error);
    }
  };

  const getStates = async () => {
    try {
      const response = await axios.post(
        "https://learning.motion.ac.in/motioneducation/api/StateCollegePredictor"
      );
      if (response.data.status === 200) {
        setState((prevState) => ({
          ...prevState,
          states: [...response.data.state_college_predictor],
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeStates = (event) => {
    const selectedState = event.target.value;
    setState((prevState) => ({
      ...prevState,
      stateSelected: selectedState,
    }));
  };

  const handleChangeCategories = (event) => {
    const selectedCategory = event.target.value;
    setState((prevState) => ({
      ...prevState,
      categorySelected: selectedCategory,
    }));
  };
  return (
    <div className="predictCollegeSection">
      <section className="cards">
        <div className="testCard">
          <div className="testCardImg">
            <img src={images.expectedIcon} alt="img" />
          </div>

          <div className="testCardRight">
            <div className="testCardRightTop">Expected Rank</div>
            <div className="testCardRightBottom expected"> {expectedRank}</div>
          </div>
        </div>
        <div className="testCard">
          <div className="testCardImg">
            <img src={images.allIndiaIcon} alt="img" />
          </div>

          <div className="testCardRight">
            <div className="testCardRightTop">All India Rank</div>
            <div className="testCardRightBottom allindia"> {allIndiaRank}</div>
          </div>
        </div>
      </section>
      <section className="clgList">
        <div className="left">
          <div className="section-Heading">College List</div>
          <p className="desc">Based on your performance</p>
        </div>
        <div className="right">
          <div className="stateDropDownWrpper">
            <select
              id="stateDropdown"
              onChange={handleChangeStates}
              value={stateSelected}
            >
              <option value="" disabled>
                Select State
              </option>
              {!isEmpty(states) &&
                states.map((element, index) => (
                  <option key={index} value={element.state}>
                    {element.state}
                  </option>
                ))}
            </select>
          </div>

          <div className="categoriesDropdownWrpper">
            <select
              id="categoriesDropdown"
              onChange={handleChangeCategories}
              value={categorySelected}
            >
              {!isEmpty(categories) &&
                categories.map((element, index) => (
                  <option key={index} value={element}>
                    {element.toUpperCase()}
                  </option>
                ))}
            </select>
          </div>
        </div>
      </section>
      {collegeDetails.length > 0 ? (
        <section className="table_section">
          <table style={{ width: "100%" }}>
            <thead className="table_headingg">
              <tr>
                <th className="firstTd">S.No.</th>
                <th>College Name</th>
                <th>Stream</th>
                <th>Eligible Rank</th>
              </tr>
            </thead>

            <tbody className="predictClgTbody">
              {!isEmpty(collegeDetails) &&
                collegeDetails.map((element, index) => (
                  <tr key={index}>
                    <td className="num">{index + 1}</td>
                    <td className="td-text num">{element?.college_name}</td>
                    <td className="stream">{element?.Stream}</td>
                    <td className="elerank">{element?.rank}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </section>
      ) : (
        <div className="no-data-wrapper">
          <td colSpan="3" className="no-data">
            <img src={images.parentNoDataIcon} alt="no-data-found" />
            <div className="no-data-msg"> No Data Found! </div>
          </td>
        </div>
      )}
       {loader && <LoaderPopUp />}
    </div>
  );
};

export default PredictCollegeContentComponent;
